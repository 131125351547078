<template>
  <section class="section bg-orangered" id="comments">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="title-box">
            <h2
              class="text-white fadeIn animated wow"
              data-wow-delay=".2s"
            >{{$t("Our Clients love us!")}}</h2>
            <h4
              class="text-white fadeIn animated wow"
              data-wow-delay=".1s"
            >{{$t("We use the most innovative technologies")}}</h4>
          </div>
        </div>
      </div>

      <div class="row">
        <article class="col-lg-4 mt-5">
          <div class="fadeIn animated wow" data-wow-delay=".1s">
            <figure class="testimonial">
              <blockquote>{{$t("Their experience in complex topics like kubernetes autoscaling have been vital for our organization")}}</blockquote>
              <div class="author">
                <img src="../../assets/images/faces/1.png" alt="face" />
                <h5>Jairo Rendon</h5>
                <span></span>
              </div>
            </figure>
          </div>
        </article>
        <article class="col-lg-4 mt-5">
          <div class="fadeIn animated wow" data-wow-delay=".1s">
            <figure class="testimonial">
              <blockquote>{{$t("Robust technology for our services, we are sure that nothing has failed in several months, it is fast and pleasant to use")}}</blockquote>
              <div class="author">
                <img src="../../assets/images/faces/2.png" alt="face" />
                <h5>Andres Ballestas</h5>
                <span></span>
              </div>
            </figure>
          </div>
        </article>
        <article class="col-lg-4 mt-5">
          <div class="fadeIn animated wow" data-wow-delay=".1s">
            <figure class="testimonial">
              <blockquote>{{$t("Their experience in complex topics like kubernetes autoscaling have been vital for our organization")}}</blockquote>
              <div class="author">
                <img src="../../assets/images/faces/3.png" alt="face" />
                <h5>Jairo Rendon</h5>
                <span></span>
              </div>
            </figure>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Comments"
};
</script>
<style scoped>
</style>



